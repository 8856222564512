import { AddressValues } from '@/strapiSections/CheckoutSection/CheckoutForm';
import * as Sentry from '@sentry/nextjs';

export function sendAnalyticsEvent(event: GtagEvent) {
  try {
    // @ts-expect-error Missing definitions for GA4
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.dataLayer?.push({
      event: event.name,
      event_category: event.category,
      event_label: event.label,
      value: event.value,
      currency: event.currency,
      non_interaction: event.nonInteractionEvent,
      items: event.items,
      transaction_id: event.transaction_id,
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function sendEcommerceEvent(event: GtagEvent, customer?: AddressValues) {
  try {
    const dataLayerEvent: {
      event: string;
      ecommerce: {
        event_category?: string;
        event_label?: string;
        value?: number;
        currency?: string;
        non_interaction?: boolean;
        items?: GtagItem[];
        transaction_id?: string;
        shipping?: number;
        tax?: number;
        purchase_type?: string;
        search_term?: string;
      };
      customer?: {
        billing_email: string;
        billing_firstname: string;
        billing_lastname: string;
        billing_address: string;
        billing_postalcode: string;
        billing_city: string;
        billing_country: string;
        billing_phone: string;
      };
    } = {
      event: event.name,
      ecommerce: {
        event_category: event.category,
        event_label: event.label,
        value: event.value,
        currency: event.currency,
        non_interaction: event.nonInteractionEvent,
        items: event.items,
        transaction_id: event.transaction_id,
        shipping: event.shipping,
        tax: event.tax,
        purchase_type: event.purchase_type,
        search_term: event.search_term,
      },
    };

    if (customer) {
      dataLayerEvent.customer = {
        billing_email: customer.email,
        billing_firstname: customer.firstName,
        billing_lastname: customer.lastName,
        billing_address: customer.address,
        billing_postalcode: customer.postNr,
        billing_city: customer.city,
        billing_country: customer.country,
        billing_phone: customer.phone,
      };
    }

    // @ts-expect-error Missing definitions for GA4
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.dataLayer?.push(dataLayerEvent);
  } catch (e) {
    Sentry.captureException(e);
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export type EventName =
  | 'add_payment_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'generate_lead'
  | 'login'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'search'
  | 'select_content'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results';

export type EventCategory = 'ecommerce' | 'engagement' | 'general';

export type PurchaseType = 'private' | 'company';

export interface GtagEvent {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  name: EventName | string;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  category?: EventCategory | string;
  label?: string;
  value?: number;
  tax?: number;
  currency?: string;
  nonInteractionEvent?: boolean;
  shipping?: number;
  items?: GtagItem[];
  transaction_id?: string;
  purchase_type?: PurchaseType;
  search_term?: string;
}

export interface GtagItem {
  item_id: string;
  item_name: string;
  price?: number;
  quantity?: number;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
}
