import { ChangeEvent } from 'react';
import { useShoppingCart } from '@/context/shoppingCartContext';

interface PriceConfigurationDropDownData {
  showPriceText: string | undefined;
  priceIncludingVatText: string | undefined;
  priceExcludingVatText: string | undefined;
}

export default function PriceConfigurationDropDown({
  showPriceText,
  priceIncludingVatText,
  priceExcludingVatText,
}: PriceConfigurationDropDownData) {
  const { displayVat, setDisplayVat } = useShoppingCart();

  function handleOptionChange(event: ChangeEvent<HTMLSelectElement>) {
    setDisplayVat(event.target.value === 'true');
  }

  return (
    <div className="flex flex-wrap items-center justify-center">
      <p className="shrink-0">
        {showPriceText ? showPriceText : 'Visa priser'}
      </p>
      <select
        value={displayVat.toString()}
        onChange={handleOptionChange}
        className="rounded-lg border-transparent bg-transparent py-1 text-xs font-bold md:text-base"
      >
        <option value="true">
          {priceIncludingVatText ? priceIncludingVatText : 'inkl moms'}
        </option>
        <option value="false">
          {priceExcludingVatText ? priceExcludingVatText : 'exkl moms'}
        </option>
      </select>
    </div>
  );
}
